import {
  getRegex,
  getAnyOf,
  isDynamicRequired,
  getError
} from './flatfileFunctions';
import Axios from 'axios';
import moment from 'moment';
import feathers from '../services/feathers';
import { toast } from 'react-toastify';
import _, { result } from 'lodash';

const splitArray = require('split-array');

const env = process.env;

const mappingData = async (docs, data, name, phone) => {
  await data.reduce(async (prev, current, index, arr) => {
    let noFDA = ['n', 'no', 'No', 'N', 'NO'];
    if (current.cargoWeightUOM) {
      switch (current.cargoWeightUOM) {
        case 'k':
        case 'kg':
        case 'KG':
          current.cargoWeightUOM = 'K';
          break;
        case 'l':
        case 'lg':
        case 'LG':
          current.cargoWeightUOM = 'L';
          break;
        default:
          break;
      }
    }
    docs.air.push({
      SMI: {
        componentId: 'FRI'
      },
      ...((current.airportOfArrival || current.cargoTerminalOperator) && {
        CCL: {
          componentId: 'CCL',
          arrivalAirport: await convertToUpperCase(current.airportOfArrival),
          terminalOp: current.cargoTerminalOperator
            ? await convertToUpperCase(current.cargoTerminalOperator)
            : ''
        }
      }),
      AWB: {
        componentId: 'AWB',
        ...(current.waybillType &&
          current.waybillType.toUpperCase() === 'MASTER' && {
            consolidationId: 'M'
          }),
        prefix: await convertToUpperCase(current.AWBPrefix),
        serialNumber: current.AWBNumber,
        hawbNumber: current.HAWBNumber ? current.HAWBNumber : '',
        pkTrackingId: current.packageTrackNo ? current.packageTrackNo : ''
      },
      WBL: {
        componentId: 'WBL',
        airportOrigin: await convertToUpperCase(current.airportOfOrigin),
        destinationAirport: current.permitToProceedDestinationAirport
          ? await convertToUpperCase(current.permitToProceedDestinationAirport)
          : '',
        numPieces: current.cargoPieceCount ? current.cargoPieceCount : '',
        weightCode: await convertToUpperCase(current.cargoWeightUOM),
        weight: current.cargoWeight,
        cargoDesc: await convertToUpperCase(current.cargoDescription),
        arrivalDatePTPAirport: current.dateofArrivalAtThePermitToProceedDestinationAirport
          ? convertDateAir(
              current.dateofArrivalAtThePermitToProceedDestinationAirport
            )
          : ''
      },
      ...((current.onwardCarrier ||
        current.flightNumber ||
        current.scheduledDateOfArrival) && {
        ARR: {
          componentId: 'ARR',
          impCarrier: current.importingCarrierAirCarrierCode
            ? await convertToUpperCase(current.importingCarrierAirCarrierCode)
            : '',
          flightNumber: await convertToUpperCase(current.flightNumber),
          arrivalDate: current.scheduledDateOfArrival
            ? convertDateAir(current.scheduledDateOfArrival)
            : '',
          arrivalRef: current.partArrivalReference
            ? await convertToUpperCase(current.partArrivalReference)
            : '',
          brdPieceCnt: current.boardedPieces ? current.boardedPieces : '',
          weightCode: await convertToUpperCase(current.boardedWeightCode),
          weight: current.boardedWeight,
          boardedQuantityId: current.boardedQuantityIdentifier
            ? await convertToUpperCase(current.boardedQuantityIdentifier)
            : ''
        }
      }),
      ...(current.AirAMSParticipantCode && {
        AGT: {
          componentId: 'AGT',
          participantCode: current.AirAMSParticipantCode
            ? await convertToUpperCase(current.AirAMSParticipantCode)
            : ''
        }
      }),
      ...((current.shipperName ||
        current.shipperCity ||
        current.shipperCountry) && {
        SHP: {
          componentId: 'SHP',
          name: await convertToUpperCase(current.shipperName),
          address: current.shipperStreetAddress
            ? await convertToUpperCase(current.shipperStreetAddress)
            : '',
          city: await convertToUpperCase(current.shipperCity),
          state: current.shipperStateOrProvince
            ? await convertToUpperCase(current.shipperStateOrProvince)
            : '',
          countryCode: await convertToUpperCase(current.shipperCountry),
          postalCode: current.shipperPostalCode
            ? await convertToUpperCase(current.shipperPostalCode)
            : '',
          phoneNumber: current.shipperTelephoneNumber
            ? current.shipperTelephoneNumber
            : ''
        }
      }),
      ...((current.consigneeName ||
        current.consigneeCity ||
        current.consigneeCountry) && {
        CNE: {
          componentId: 'CNE',
          name: await convertToUpperCase(current.consigneeName),
          address: current.consigneeStreetAddress
            ? await convertToUpperCase(current.consigneeStreetAddress)
            : '',
          city: await convertToUpperCase(current.consigneeCity),
          state: current.consigneeStateOrProvince
            ? await convertToUpperCase(current.consigneeStateOrProvince)
            : '',
          countryCode: await convertToUpperCase(current.consigneeCountry),
          postalCode: current.consigneePostalCode
            ? await convertToUpperCase(current.consigneePostalCode)
            : '',
          phoneNumber: current.consigneeTelephoneNumber
            ? current.consigneeTelephoneNumber
            : ''
        }
      }),
      ...(current.inbondDestinationAirport && {
        TRN: {
          componentId: 'TRN',
          destinationAirport: await convertToUpperCase(
            current.inbondDestinationAirport
          ),
          transferstatusId: current.inbondDestinationType
            ? await convertToUpperCase(current.inbondDestinationType)
            : '',
          bondedCarrierId: current.bondedCarrierId
            ? await convertToUpperCase(current.bondedCarrierId)
            : '',
          onwardCarrier: current.onwardCarrier
            ? await convertToUpperCase(current.onwardCarrier)
            : '',
          premisesId: current.bondedPremisesIdentifier
            ? await convertToUpperCase(current.bondedPremisesIdentifier)
            : '',
          inbondControlNum: current.inbondControlNumber
            ? await convertToUpperCase(current.inbondControlNumber)
            : ''
        }
      }),
      ...(current.shipmentValue && {
        CSD: {
          componentId: 'CSD',
          originGoods: current.countryOfOrigin
            ? await convertToUpperCase(current.countryOfOrigin)
            : '',
          declaredValue: current.shipmentValue,
          currencyCode: current.currencyCode
            ? await convertToUpperCase(current.currencyCode)
            : '',
          hazmatCode: current.harmonizedCode
            ? await convertToUpperCase(current.harmonizedCode)
            : ''
        }
      }),
      ...(current.FDAIndicator &&
        !noFDA.includes(current.FDAIndicator) && {
          FDA: { lineId: 'FDA' }
        }),
      ...(current.MarksAndNumbers && {
        marksAndNumbers: await convertToUpperCase(current.MarksAndNumbers)
      })
    });

    if (
      current.includeType86 &&
      (await convertToUpperCase(current.includeType86)) === 'Y'
    ) {
      docs.type86.push({
        entryNumber: current.entryNumber,
        entryType: current.entryType,
        IORType: current.IORType,
        IORNumber: current.IORNumber,
        modeOfTransport: current.modeOfTransport,
        bondType: current.bondType,
        portOfEntry: current.portOfEntry,
        FIRMS: current.FIRMS,
        manifestNumber: current.flightNumber,
        dateOfArrival: current.T86DateOfArrival
          ? convertDateT86(current.T86DateOfArrival)
          : '', //change date format
        filerContactName: name,
        filerPhoneNumber: phone,
        billType:
          current.waybillType.toUpperCase() === 'MASTER'
            ? 'M'
            : current.waybillType.toUpperCase() === 'HOUSE'
            ? 'M'
            : 'R',
        MBOLNumber: current.AWBPrefix + current.AWBNumber,
        billTypeHouse: current.HAWBNumber === '' ? '' : 'H',
        HBOLNumber: current.HAWBNumber === '' ? '' : current.HAWBNumber,
        totalQuantity: current.cargoPieceCount ? current.cargoPieceCount : '',
        sellerName: current.shipperName ? current.shipperName : '',
        sellerAddress1: current.shipperStreetAddress
          ? current.shipperStreetAddress
          : '',
        sellerCity: current.shipperCity ? current.shipperCity : '',
        sellerStateOrProvince: current.shipperStateOrProvince
          ? current.shipperStateOrProvince
          : '',
        shipperPostalCode: current.shipperPostalCode
          ? current.shipperPostalCode
          : '',
        sellerCountry: current.shipperCountry ? current.shipperCountry : '',
        sellerPhoneNumber: current.shipperTelephoneNumber
          ? current.shipperTelephoneNumber
          : '',
        consigneeName: current.consigneeName ? current.consigneeName : '',
        consigneeIdentifierCode: current.consigneeIdentifierCode
          ? current.consigneeIdentifierCode
          : '',
        consigneeTaxID: current.consigneeTaxID ? current.consigneeTaxID : '',
        consigneeAddress1: current.consigneeStreetAddress
          ? current.consigneeStreetAddress
          : '',
        consigneeAddress2: current.consigneeAddress2
          ? current.consigneeAddress2
          : '',
        consigneeCity: current.consigneeCity ? current.consigneeCity : '',
        consigneeStateOrProvince: current.consigneeStateOrProvince
          ? current.consigneeStateOrProvince
          : '',
        consigneePostalCode: current.consigneePostalCode
          ? current.consigneePostalCode
          : '',
        consigneeCountry: current.consigneeCountry
          ? current.consigneeCountry
          : '',
        consigneeEmail: current.consigneeEmail ? current.consigneeEmail : '',
        consigneePhoneNumber: current.consigneeTelephoneNumber
          ? current.consigneeTelephoneNumber
          : '',
        equipmentNumber: current.equipmentNumber ? current.equipmentNumber : '',
        HTSNumber1: current.HTSNumber1 ? current.HTSNumber1 : '',
        description1: current.description1
          ? await convertToUpperCase(current.description1)
          : current.description1 === ''
          ? current.cargoDescription.slice(0, 70)
          : await convertToUpperCase(current.cargoDescription.slice(0, 70)),
        lineItemValue1: current.lineItemValue1 ? current.lineItemValue1 : '',
        countryOfOrigin1: current.countryOfOrigin1
          ? current.countryOfOrigin1
          : '',
        PGAFDADisclaimer1: current.PGAFDADisclaimer1
          ? current.PGAFDADisclaimer1
          : '',
        HTSNumber2: current.HTSNumber2 ? current.HTSNumber2 : '',
        description2: current.description2 ? current.description2 : '',
        lineItemValue2: current.lineItemValue2 ? current.lineItemValue2 : '',
        countryOfOrigin2: current.countryOfOrigin2
          ? current.countryOfOrigin2
          : '',
        PGAFDADisclaimer2: current.PGAFDADisclaimer2
          ? current.PGAFDADisclaimer2
          : '',
        HTSNumber3: current.HTSNumber3 ? current.HTSNumber3 : '',
        description3: current.description3 ? current.description3 : '',
        lineItemValue3: current.lineItemValue3 ? current.lineItemValue3 : '',
        countryOfOrigin3: current.countryOfOrigin3
          ? current.countryOfOrigin3
          : '',
        PGAFDADisclaimer3: current.PGAFDADisclaimer3
          ? current.PGAFDADisclaimer3
          : '',
        HTSNumber4: current.HTSNumber4 ? current.HTSNumber4 : '',
        description4: current.description4 ? current.description4 : '',
        lineItemValue4: current.lineItemValue4 ? current.lineItemValue4 : '',
        countryOfOrigin4: current.countryOfOrigin4
          ? current.countryOfOrigin4
          : '',
        PGAFDADisclaimer4: current.PGAFDADisclaimer4
          ? current.PGAFDADisclaimer4
          : '',
        HTSNumber5: current.HTSNumber5 ? current.HTSNumber5 : '',
        description5: current.description5 ? current.description5 : '',
        lineItemValue5: current.lineItemValue5 ? current.lineItemValue5 : '',
        countryOfOrigin5: current.countryOfOrigin5
          ? current.countryOfOrigin5
          : '',
        PGAFDADisclaimer5: current.PGAFDADisclaimer5
          ? current.PGAFDADisclaimer5
          : '',
        HTSNumber6: current.HTSNumber6 ? current.HTSNumber6 : '',
        description6: current.description6 ? current.description6 : '',
        lineItemValue6: current.lineItemValue6 ? current.lineItemValue6 : '',
        countryOfOrigin6: current.countryOfOrigin6
          ? current.countryOfOrigin6
          : '',
        PGAFDADisclaimer6: current.PGAFDADisclaimer6
          ? current.PGAFDADisclaimer6
          : '',
        HTSNumber7: current.HTSNumber7 ? current.HTSNumber7 : '',
        description7: current.description7 ? current.description7 : '',
        lineItemValue7: current.lineItemValue7 ? current.lineItemValue7 : '',
        countryOfOrigin7: current.countryOfOrigin7
          ? current.countryOfOrigin7
          : '',
        PGAFDADisclaimer7: current.PGAFDADisclaimer7
          ? current.PGAFDADisclaimer7
          : '',
        HTSNumber8: current.HTSNumber8 ? current.HTSNumber8 : '',
        description8: current.description8 ? current.description8 : '',
        lineItemValue8: current.lineItemValue8 ? current.lineItemValue8 : '',
        countryOfOrigin8: current.countryOfOrigin8
          ? current.countryOfOrigin8
          : '',
        PGAFDADisclaimer8: current.PGAFDADisclaimer8
          ? current.PGAFDADisclaimer8
          : '',
        inBondNumber: current.inBondNumber ? current.inBondNumber : '',
        splitShipmentReleaseCode: current.splitShipmentReleaseCode
          ? current.splitShipmentReleaseCode
          : '',
        suretyCode: current.suretyCode ? current.suretyCode : '',
        bondAmount: current.bondAmount ? current.bondAmount : '',
        expressConsigmentShipment: current.expressConsigmentShipment
          ? current.expressConsigmentShipment
          : '',
        knownImporter: current.knownImporter ? current.knownImporter : '',
        perishableGoods: current.perishableGoods ? current.perishableGoods : '',
        additionalReferenceCodeA: current.additionalReferenceCodeA
          ? current.additionalReferenceCodeA
          : '',
        additionalReferenceNumberA: current.additionalReferenceNumberA
          ? current.additionalReferenceNumberA
          : '',
        additionalReferenceCodeB: current.additionalReferenceCodeB
          ? current.additionalReferenceCodeB
          : '',
        additionalReferenceNumberB: current.additionalReferenceNumberB
          ? current.additionalReferenceNumberB
          : ''
      });
    }
    console.log('docs :>> ', docs);
    return docs;
  }, []);
};

const getFieldsToRegex = (properties, required) => {
  let fields = [],
    fieldFormatter = [],
    fieldNoDashesAndDots = [],
    fieldTrim = [],
    fieldSubstring = [],
    fieldUpperCase = [],
    fieldRound = [];

  for (const key in properties) {
    let field = properties[key];
    let fieldFlatfile = getRegex(key, properties[key]);
    required.includes(key) &&
      fieldFlatfile.validators.unshift({ validate: 'required' });

    if (field.fieldFormatter) fieldFormatter.push(key);

    field.fieldNoDashesAndDots && fieldNoDashesAndDots.push(key);

    field.fieldTrim && fieldTrim.push(key);

    if (field.fieldSubstring) fieldSubstring.push(key);

    if (field.fieldRound) fieldRound.push(key);

    if (field.fieldUpperCase) fieldUpperCase.push(key);

    fields.push(fieldFlatfile);
  }
  return {
    fields,
    fieldFormatter,
    fieldNoDashesAndDots,
    fieldTrim,
    fieldSubstring,
    fieldUpperCase,
    fieldRound
  };
};

const getMapSchema = async data => {
  if (!data) return [];
  let docs = { type86: [], air: [] };

  let userProfile2 = localStorage.profile
    ? JSON.parse(localStorage.profile)
    : {};

  if (userProfile2.oceanToken) {
    await Axios.get(
      `${env['REACT_APP_CCOCEAN_FEATHERS_URL']}/organizations`,
      {
        params: { _id: userProfile2.oceanOrganizationId }
      },
      {
        headers: {
          Authorization: userProfile2.oceanToken
        }
      }
    ).then(async function(dataOrganization) {
      let name, phone;
      let dataResponse, status;
      dataResponse = dataOrganization && dataOrganization.data;
      status = dataOrganization && dataOrganization.status;
      if (dataResponse && status === 200) {
        const { data: org } = dataResponse;
        if (org[0]) {
          const {
            name: orgName,
            profile: { contactName, contactLastName, phoneNumber }
          } = org[0];
          name =
            contactName && contactLastName
              ? `${contactName} ${contactLastName}`
              : orgName;
          phone = phoneNumber;
        }
      }

      console.log('getting data mapped');
      await mappingData(docs, data, name, phone);
    });
  } else {
    await mappingData(docs, data, '', '');
  }

  return docs;
};

const convertDateAir = date => {
  let month = date.substring(0, 2);
  let day = date.substring(2, 4);
  let year = date.substring(4, 8);
  let finalDate = moment(`${month}/${day}/${year}`)
    .format('DDMMM')
    .toUpperCase();
  return finalDate;
};

const convertDateT86 = date => {
  let month = date.substring(0, 2);
  let day = date.substring(2, 4);
  let year = date.substring(4, 8);
  let newDate = year + month + day;
  return newDate;
};

const getRealNameColumns = (array, schema) => {
  let { fields } = getFieldsToRegex(schema.properties, schema.required);
  let newArray = [];
  for (const object of array) {
    if (object.includeType86 && object.includeType86.toUpperCase() === 'Y') {
      let newObject = {};
      for (const key of Object.keys(object)) {
        for (const field of fields) {
          if (field.key === key) {
            newObject[field.label] = convertToUpperCase(object[key]);
          }
        }
      }
      newArray.push(newObject);
    }
  }
  return newArray;
};

const convertToUpperCase = async value => {
  return (
    ((await value) &&
      (typeof value === 'string' || value instanceof String) &&
      value.toUpperCase()) ||
    value
  );
};

const isTrim = async (value, maxLength) => {
  return ((await value) && maxLength && value.slice(0, maxLength)) || value;
};

const isSubstring = async value => {
  return value.length > 12
    ? (await value) && value.substring(value.length - 12)
    : value;
};

const mathRound = async (index, value) => {
  return (
    ((await value) &&
      ((index.includes('cargoWeight') && Math.trunc(value) === 0 && '1') ||
        (!index.includes('cargoWeight') &&
          value.includes('.') &&
          Math.round(value).toString()))) ||
    value
  );
};

const isFormatter = async value => {
  return (
    (value &&
      value
        .replace(/[-\.]+/g, '')
        .replace(/[…]+/g, '')
        .trim()) ||
    value
  );
};

const noDashesAndDots = async value => {
  return (
    ((await value) &&
      value
        .replace(/[^A-Za-z\d]/g, ' ')
        .replace(/\n/g, '')
        .replace(/\s+/g, ' ')
        .trim()) ||
    value
  );
};

const isUpperCase = async value => {
  return ((await value) && (await convertToUpperCase(value))) || value;
};

const convertData = async (row, state) => {
  try {
    let out = {};

    await Object.entries(row).forEach(async ([name, value]) => {
      let item = (out[name] && out[name].value) || value;

      state.fieldFormatter.includes(name) &&
        item &&
        (out[name] = await {
          ...out[name],
          ...{
            value: await isFormatter(item).then(async i => {
              item = i;
              row[name] = await i;
              return i;
            }),
            info: getError(
              `Special characters were removed the ${name}`,
              `info`
            ).info.concat((out[name] && out[name].info) || [])
          }
        });

      state.fieldNoDashesAndDots.includes(name) &&
        item &&
        (out[name] = await {
          ...out[name],
          ...{
            value: await noDashesAndDots(item).then(async i => {
              item = i;
              row[name] = await i;
              return i;
            }),
            info: getError(
              `Dashes and dots have been removed the ${name}`,
              `info`
            ).info.concat((out[name] && out[name].info) || [])
          }
        });

      state.fieldTrim.includes(name) &&
        item &&
        (out[name] = await {
          ...out[name],
          ...{
            value: await isTrim(
              item,
              state.schema &&
                state.schema.properties &&
                state.schema.properties[name].maxLength
            ).then(async i => {
              item = i;
              row[name] = await i;
              return i;
            }),
            info: getError(
              `Word size was adjusted in ${name}`,
              `info`
            ).info.concat((out[name] && out[name].info) || [])
          }
        });

      state.fieldSubstring.includes(name) &&
        item &&
        (out[name] = await {
          ...out[name],
          ...{
            value: await isSubstring(item).then(async i => {
              item = i;
              row[name] = await i;
              return i;
            }),
            info: getError(
              `HAWBNumber size was adjusted in ${name}`,
              `info`
            ).info.concat((out[name] && out[name].info) || [])
          }
        });

      state.fieldUpperCase.includes(name) &&
        item &&
        (out[name] = await {
          ...out[name],
          ...{
            value: await isUpperCase(item).then(async i => {
              item = i;
              row[name] = await i;
              return i;
            }),
            info: getError(
              `It changes to uppercase the ${name}`,
              `info`
            ).info.concat((out[name] && out[name].info) || [])
          }
        });

      state.fieldRound.includes(name) &&
        item &&
        (out[name] = await {
          ...out[name],
          ...{
            value: await mathRound(name, item).then(async i => {
              item = i;
              row[name] = await i;
              return i;
            }),
            info: getError(
              `The value was adjusted in ${name}`,
              `info`
            ).info.concat((out[name] && out[name].info) || [])
          }
        });

      state.anyOfMap[name] &&
        Object.entries(
          await isDynamicRequired(name, item, row, state.anyOfMap)
        ).forEach(async ([key, value]) => {
          out[key] = {
            info: await value.info.concat((out[key] && out[key].info) || [])
          };
        });
    });

    return await { out, row };
  } catch (error) {
    console.error('ERROR', error);
  }
};

const specialValidations = async row => {
  let out = {};

  row['waybillType'] &&
    row['waybillType'].toUpperCase() === 'HOUSE' &&
    (out = {
      ...out,
      ...{
        ...((row['importingCarrierAirCarrierCode'] ||
          row['flightNumber'] ||
          row['scheduledDateOfArrival']) && {
          ...(!row['importingCarrierAirCarrierCode'] && {
            importingCarrierAirCarrierCode: getError(
              `Required importingCarrierAirCarrierCode`,
              'error'
            )
          }),
          ...(!row['flightNumber'] && {
            flightNumber: getError(`Required flightNumber`, 'error')
          }),
          ...(!row['scheduledDateOfArrival'] && {
            scheduledDateOfArrival: getError(
              `Required scheduledDateOfArrival`,
              'error'
            )
          })
        }),
        ...((row['cargoTerminalOperator'] || row['airportOfArrival']) && {
          ...(!row['airportOfArrival'] && {
            airportOfArrival: getError(`Required airportOfArrival`, 'error')
          }),
          ...(!row['cargoTerminalOperator'] && {
            cargoTerminalOperator: getError(
              `Required cargoTerminalOperator`,
              'error'
            )
          })
        })
      }
    });

  let lineItemValueTotal = 0;
  let errorLineItemValueTotal = {};
  for (let index = 1; index <= 8; index++) {
    let name = `lineItemValue${index}`;
    if (row[name]) {
      let value = parseFloat(row[name]);
      lineItemValueTotal += value;
      (value === 0 &&
        (out[name] = getError(
          `Value equal to zero is not allowed`,
          'error'
        ))) ||
        (errorLineItemValueTotal[name] = getError(
          `Section 321 shipments cannot exceed $800`,
          'error'
        ));
    }
  }

  lineItemValueTotal > 800 &&
    (out = Object.assign(out, errorLineItemValueTotal));

  row['cargoWeight'] &&
    parseFloat(row['cargoWeight']) === 0 &&
    (out['cargoWeight'] = getError(
      'Value equal to zero is not allowed',
      'error'
    ));

  return out;
};

const createGroupsToDeleteAirDocuments = async data => {
  let groups = [];
  let groupsByType = _.groupBy(data, function(d) {
    return [d.waybillType];
  });

  for (const key in groupsByType) {
    const element = groupsByType[key];
    let type = key.toUpperCase();

    if (type === 'MASTER' || type === 'SIMPLE') {
      let doc = {
        type: type,
        data: { prefix: element[0].AWBPrefix, serialNumbers: [] }
      };

      element.map(row => {
        doc.data.serialNumbers.push(row.AWBNumber);
      });

      groups.push(doc);
    } else if (type === 'HOUSE') {
      let doc = {
        type: type,
        data: { prefix: element[0].AWBPrefix, serialNumbers: [] }
      };

      let groupHouseBySerial = _.groupBy(element, function(d) {
        return [d.AWBNumber];
      });

      for (const key in groupHouseBySerial) {
        const houseElement = groupHouseBySerial[key];
        let house = { serialNumber: key, houses: [] };

        houseElement.map(row => {
          house.houses.push(row.HAWBNumber);
        });

        doc.data.serialNumbers.push(house);
      }

      groups.push(doc);
    }
  }

  return groups;
};

export const getSuscriptionType86 = async () => {
  let userProfile = localStorage.profile
    ? JSON.parse(localStorage.profile)
    : {};

  return await feathers
    .service('suscriptions')
    .find({
      query: {
        company: userProfile.company,
        user: userProfile.id
      }
    })
    .then(function(data) {
      if (data && data.length > 0) {
        let action = data[0].action[0];
        if (
          action &&
          action.messageTypes &&
          action.messageTypes.includes('TYPE86')
        ) {
          return { sendEmail: true };
        }
      }

      return { sendEmail: false };
    });
};

export const saveAirAMSType86 = async (
  dataResult,
  data,
  saveType86,
  schema,
  sendEmail,
  batch
) => {
  let messageError;
  let listError;
  let isSuccess;
  let responses;
  try {
    let userProfile = localStorage.profile
      ? JSON.parse(localStorage.profile)
      : {};

    if (data) {
      console.log('enviando a upload :>> ');
      Axios.defaults.headers.common['Authorization'] = localStorage.token;
      await Axios.post(`${env['REACT_APP_CCAPI']}/cbp/air/upload3/`, data.air, {
        timeout: 1800000
      }).then(async function(response) {
        responses = response;
        if (response.data.success) {
          localStorage.setItem('endDate', moment());
          //Enviar notificacion de subida CSV
          Axios.defaults.headers.common['Authorization'] = localStorage.token;
          console.log('sending noti');
          await Axios.post(`${env['REACT_APP_CCAPI']}/csv/emailNotification/`, {
            data: userProfile,
            AWBNumber:
              data.air[0].AWB.prefix + ' - ' + data.air[0].AWB.serialNumber
          }).then(function(response2) {
            if (!response2.data.success) {
              if (response2.data && response2.data.message) {
                messageError = JSON.stringify(response2.data.message, null, 4);
              }
            } else {
              toast.success('Successfully sent by email notification TEST');
            }
          });
        }
        if (
          response.data &&
          response.data.success &&
          data.type86 &&
          data.type86.length > 0 &&
          saveType86 === true
        ) {
          console.log('enviando t86 :>> ');
          await Axios.post(
            `${env['REACT_APP_CCOCEAN_FEATHERS_URL']}/parse`,
            {
              type: 'abi-type86',
              typeOfValidation: 'abi-type86',
              typeOfSend: '309',
              partnershipId: '5ed07dbae1e0368f7a14efac',
              body: data.type86,
              send: false,
              organization: userProfile.oceanOrganizationId
            },
            {
              headers: {
                Authorization: userProfile.oceanToken
              },
              timeout: 1800000
            }
          )
            .then(async function(resultEdit) {
              console.log('Type 86 respondio');
              if (resultEdit.statusText === 'Created') {
                let csv = getRealNameColumns(dataResult, schema);
                if (
                  Array.isArray(csv) &&
                  csv.length > 0 &&
                  sendEmail === true
                ) {
                  Axios.defaults.headers.common['Authorization'] =
                    localStorage.token;
                  await Axios.post(`${env['REACT_APP_CCAPI']}/csv/email/`, {
                    data: csv
                  }).then(function(response2) {
                    if (response2.data && !response2.data.success) {
                      if (response2.data && response2.data.message) {
                        messageError = JSON.stringify(
                          response2.data.message,
                          null,
                          4
                        );
                      }
                    } else {
                      toast.success('CSV Type 86 successfully sent by email');
                    }
                  });
                }
              }
            })
            .catch(async function(error) {
              console.error('Error', error.response ? error.response : error);
              isSuccess = 'Errors!';

              messageError =
                (error.response &&
                  error.response.data &&
                  JSON.stringify(error.response.data.message, null, 4)) ||
                error.message;

              if (responses && responses.data && responses.data.success) {
                let groupToDelete = await createGroupsToDeleteAirDocuments(
                  dataResult
                );

                Axios.defaults.headers.common['Authorization'] =
                  localStorage.token;
                await Axios.post(
                  `${env['REACT_APP_CCAPI']}/documents/deletemanifestsgroup/`,
                  groupToDelete
                ).then(function() {
                  if (
                    error.response &&
                    error.response.data &&
                    error.response.data.errors &&
                    error.response.data.errors.length
                  ) {
                    isSuccess = 'Document with Errors!';
                    let errorsList = [];
                    for (const err of error.response.data.errors) {
                      let item = {
                        key:
                          'AWB Number in line: ' +
                          err.line +
                          ', batch: ' +
                          batch,
                        row: err.line,
                        batch: batch
                      };

                      err.errors.map(msg => {
                        item.message = msg;
                        errorsList.push({ ...item });
                      });
                    }
                    messageError = JSON.stringify(errorsList, null, 4);
                    listError = errorsList;
                  }
                });
              }
            });
        } else {
          if (!response.data.success) {
            isSuccess = JSON.stringify(response.data.message);
          }
          messageError = JSON.stringify(response.data.docsWithErrors, null, 4);

          listError = response.data.docsWithErrors;
          if (listError && listError.length) {
            for (const err of listError) {
              err.key += ', batch: ' + batch;
            }
          }
        }
      });
    }
  } catch (error) {
    console.error('Error', error.response ? error.response : error);
    isSuccess = 'Errors!';

    messageError =
      (error.response &&
        error.response.data &&
        JSON.stringify(error.response.data.message, null, 4)) ||
      error.message;
  } finally {
    return {
      message: isSuccess ? isSuccess : 'Success!',
      ...(messageError && { error: messageError }),
      ...(listError && { errors: listError })
    };
  }
};

export const saveAirAMS = async (dataResult, data) => {
  let messageError;
  let listError;
  let isSuccess;
  let responses;
  try {
    let userProfile = localStorage.profile
      ? JSON.parse(localStorage.profile)
      : {};

    if (data) {
      Axios.defaults.headers.common['Authorization'] = localStorage.token;
      await Axios.post(`${env['REACT_APP_CCAPI']}/cbp/air/upload4/`, data.air, {
        timeout: 1800000
      }).then(async function(response) {
        responses = response;
        if (!response.data.success) {
          isSuccess = JSON.stringify(response.data.message);
        }
        messageError = JSON.stringify(response.data.docsWithErrors, null, 4);
        listError = response.data.docsWithErrors;
      });
    }
  } catch (error) {
    console.error('Error', error.response ? error.response : error);
    isSuccess = 'Errors!';

    messageError =
      (error.response &&
        error.response.data &&
        JSON.stringify(error.response.data.message, null, 4)) ||
      error.message;
  } finally {
    return {
      message: isSuccess ? isSuccess : 'Success!',
      ...(messageError && { error: messageError }),
      ...(listError && { errors: listError })
    };
  }
};

const avg = (val = 0, total = 100) => Math.ceil((val / total) * 100);

export const sendData = async (
  results,
  schema,
  sendEmail,
  setOpen,
  setProgress,
  setErrorProgress
) => {
  try {
    setOpen && setOpen(true);
    let data = await getMapSchema(results.data);
    const splitBy = 5000;
    const splitData = {
      airSplit: splitArray(data.air, splitBy),
      t86Split: splitArray(data.type86 || [], splitBy),
      resultDataSplit: splitArray(results.data, splitBy)
    };
    let userProfile = localStorage.profile
      ? JSON.parse(localStorage.profile)
      : {};

    let total = results.data.length;
    let progress = 0;
    let progressWithErrors = 0;
    let savedData;
    const temporaryErrors = [];
    let temporaryErrorMessage = '';
    let noOrgSaveData;
    const noOrgResultData = [];
    const noOrgDataMapped = {
      air: [],
      type86: []
    };
    let tempResponseMsg = [];
    setProgress &&
      setProgress(
        avg(progress, total),
        `${progress} of ${total} documents created...`
      );
    for (let i = 0; i < splitData.resultDataSplit.length; i++) {
      if (splitData.t86Split[i] && splitData.t86Split[i].length > 0) {
        if (userProfile.oceanToken) {
          let startBatch = i * splitBy + 1;
          let endBatch = (i + 1) * splitBy;
          if (splitData.resultDataSplit.length - 1 == i) {
            endBatch = total;
          }

          savedData = await saveAirAMSType86(
            splitData.resultDataSplit[i],
            {
              air: splitData.airSplit[i],
              type86: splitData.t86Split[i]
            },
            true,
            schema,
            sendEmail,
            `${startBatch} to ${endBatch}`
          );

          tempResponseMsg.push(savedData.message);
          if (savedData.errors && savedData.errors.length) {
            temporaryErrors.push(...savedData.errors);
            progressWithErrors += splitData.resultDataSplit[i].length;
          } else if (savedData.error) {
            temporaryErrorMessage += temporaryErrorMessage
              ? ',' + savedData.error
              : savedData.error;
            progressWithErrors += splitData.resultDataSplit[i].length;
          } else {
            progress += splitData.resultDataSplit[i].length;
          }
        } else {
          noOrgSaveData = {
            message: 'Warning! Please review the next information.',
            showModal: true
          };
          noOrgResultData.push(...splitData.resultDataSplit[i]);
          noOrgDataMapped.air.push(...splitData.airSplit[i]);
          noOrgDataMapped.type86.push(...splitData.t86Split[i]);
        }
      } else {
        savedData = await saveAirAMSType86(
          splitData.resultDataSplit[i],
          {
            air: splitData.airSplit[i],
            type86: splitData.t86Split[i]
          },
          false,
          schema,
          sendEmail,
          i + 1
        );

        tempResponseMsg.push(savedData.message);
        if (savedData.errors && savedData.errors.length) {
          temporaryErrors.push(...savedData.errors);
          progressWithErrors += splitData.resultDataSplit[i].length;
        } else {
          progress += splitData.resultDataSplit[i].length;
        }
      }
      if (!temporaryErrors.length && !temporaryErrorMessage) {
        setProgress &&
          setProgress(
            avg(progress, total),
            `${progress} of ${total} documents created...`
          );
      } else {
        setErrorProgress(
          avg(progressWithErrors, total),
          `${progressWithErrors} of ${total} contain errors...`,
          avg(progress, total),
          `${progress} of ${total} documents created...`
        );
      }
    }

    if (temporaryErrors.length) {
      savedData.error = JSON.stringify(temporaryErrors);
      savedData.errors = temporaryErrors;
    } else if (temporaryErrorMessage) {
      savedData.error = temporaryErrorMessage;
    } else {
      if (savedData) {
        delete savedData.error;
        delete savedData.errors;
      }
    }

    if (
      noOrgSaveData &&
      noOrgResultData.length &&
      (noOrgDataMapped.air.length || noOrgDataMapped.type86.length)
    ) {
      noOrgSaveData.resultData = noOrgResultData;
      noOrgSaveData.dataMapped = noOrgDataMapped;

      return noOrgSaveData;
    }

    let noSuccess = await tempResponseMsg.filter(m => !m.includes('Success'));
    if (noSuccess && noSuccess.length > 0) {
      savedData.message = noSuccess[0];
    }

    setTimeout(() => setOpen && setOpen(false), 3000);
    setTimeout(() => {}, 2000);
    return savedData;
  } catch (error) {
    console.log({ error });
  } finally {
    setOpen && setOpen(false);
  }
};

export const sendDataOnly = async (data, setData) => {
  let messageError;
  let isSuccess = false;

  try {
    if (!setData) throw new Error('Unassigned Data');

    setData(data);
    isSuccess = true;
  } catch (error) {
    isSuccess = false;
    console.error('Error', error);
    messageError = error && error.toString().replace('Error: ', '');
  } finally {
    return {
      message: isSuccess ? 'Success!' : 'Error!',
      ...(messageError && { error: messageError })
    };
  }
};

export const sendDataComp = async (
  results,
  schema,
  sendEmail,
  setOpen,
  setProgress,
  setErrorProgress
) => {
  try {
    setOpen && setOpen(true);
    let data = await getMapSchema(results.data);
    const splitData = {
      airSplit: splitArray(data.air, 5000),
      resultDataSplit: splitArray(results.data, 5000)
    };
    let total = results.data.length;
    let progress = 0;
    let progressWithErrors = 0;
    let savedData;
    const temporaryErrors = [];
    let temporaryErrorMessage = '';
    let noOrgSaveData;
    const noOrgResultData = [];
    const noOrgDataMapped = {
      air: []
    };
    setProgress &&
      setProgress(
        avg(progress, total),
        `${progress} of ${total} documents created...`
      );
    for (let i = 0; i < splitData.resultDataSplit.length; i++) {
      savedData = await saveAirAMS(
        splitData.resultDataSplit[i],
        {
          air: splitData.airSplit[i]
        },
        false,
        schema
      );
      if (savedData.errors && savedData.errors.length) {
        temporaryErrors.push(...savedData.errors);
        progressWithErrors += splitData.resultDataSplit[i].length;
      } else {
        progress += splitData.resultDataSplit[i].length;
      }
      if (!temporaryErrors.length && !temporaryErrorMessage) {
        setProgress &&
          setProgress(
            avg(progress, total),
            `${progress} of ${total} documents created...`
          );
      } else {
        setErrorProgress(
          avg(progressWithErrors, total),
          `${progressWithErrors} of ${total} contain errors...`,
          avg(progress, total),
          `${progress} of ${total} documents created...`
        );
      }
    }

    if (temporaryErrors.length) {
      savedData.error = JSON.stringify(temporaryErrors);
      savedData.errors = temporaryErrors;
    } else if (temporaryErrorMessage) {
      savedData.error = temporaryErrorMessage;
    } else {
      if (savedData) {
        delete savedData.error;
        delete savedData.errors;
      }
    }

    if (noOrgSaveData && noOrgResultData.length && noOrgDataMapped.air.length) {
      noOrgSaveData.resultData = noOrgResultData;
      noOrgSaveData.dataMapped = noOrgDataMapped;
      return noOrgSaveData;
    }
    setTimeout(() => setOpen && setOpen(false), 3000);
    setTimeout(() => {}, 2000);
    return savedData;
  } catch (error) {
    console.log({ error });
  } finally {
    setOpen && setOpen(false);
  }
};

export const registerRecordHook = async (record, state) => {
  try {
    return await convertData(record, state).then(async ({ out, row }) => {
      await Object.entries(await specialValidations(row)).forEach(
        async ([key, value]) => {
          (out[key] &&
            (out[key] = await {
              ...out[key],
              ...{
                ...(out[key].value && { value: await out[key].value }),
                info: await value.info.concat((out[key] && out[key].info) || [])
              }
            })) ||
            (out[key] = value);
        }
      );

      return await out;
    });
  } catch (error) {
    console.error('ERROR HOOK', error);
  }
};

export const createFlatfile = schema => {
  return {
    title: schema.title || 'N/A',
    type: schema.type,
    ...(schema.template && {
      template: schema.template,
      templateVercion: schema.templateVercion || '1'
    }),
    ...(schema.guide && {
      guide: schema.guide,
      guideVercion: schema.guideVercion || '1'
    }),
    ...getFieldsToRegex(schema.properties, schema.required),
    anyOfMap: getAnyOf(schema.anyOf),
    schema
  };
};

export default null;
