import React, { useEffect, useState, Fragment } from 'react';
import Axios from 'axios';
import { ListItem, MenuButton } from 'react-md';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import { CircularProgress, SelectField, FontIcon } from 'react-md';
import { Button } from '@material-ui/core';
import { toast } from 'react-toastify';
import Status from './components/Status';
import './style.css';
import {
  Grid,
  Table,
  TableHeaderRow,
  TableColumnResizing,
  PagingPanel,
  TableFilterRow,
  TableSelection,
  VirtualTable,
} from '@devexpress/dx-react-grid-material-ui';
import { FSCMessage } from '../../../components/awb-history/Codes';
import {
  SortingState,
  IntegratedSorting,
  PagingState,
  IntegratedPaging,
  RowDetailState,
  FilteringState,
  IntegratedFiltering,
  SelectionState,
  IntegratedSelection,
} from '@devexpress/dx-react-grid';
import feathers from '../../../services/feathers';
import moment from 'moment';
import DialogForm from '../../../components/common/DialogForm';
import FSQ from '../../../components/documents/FSQ';
import FSN from '../../../components/documents/FSN';
import FDM from '../../../components/documents/FDM';
import DeleteManifestDraftsView from '../../../components/documents/DeleteManifestDrafts';
import PagingContainer from '../../../components/GridComponents/PagingPanel';
import CreateFSN from './CreateFSN';
import SentModal from '../../../components/SentModal';

const HighlightedCell = ({ value }) => (
  <Table.Cell
    style={{
      overflow: 'visible',
    }}
  >
    {value}
  </Table.Cell>
);

const Cell = (props) => {
  const { column } = props;
  if (column.name === 'options' || column.name === 'actions') {
    return <HighlightedCell {...props} />;
  }
  if (column.name === 'hawb') {
    return <HAWBCell {...props} />;
  }
  if (column.name === 'status') {
    return (
      <Table.Cell
        {...props}
        style={{ cursor: 'pointer' }}
        onClick={async (e) => {
          await props.tableColumn.column.setRow({
            currentTab: 0,
            index: 0,
            row: props.tableRow.row,
            prefix: props.tableRow.row.prefix,
            serialNumber: props.tableRow.row.serialNumber,
            hawbNumber: props.tableRow.row.hawbNumber,
            isHouse: true,
            type: 'House',
            impCarrier: props.tableRow.row.impCarrier,
            arrivalDate: props.tableRow.row.arrivalDate,
            flightNumber: props.tableRow.row.flightNumber,
            // Cambiar por la fecha de creacion
            date:
              props.tableRow.row.manifestCreated ||
              props.tableRow.row.lastRequestDate,
          });
          await props.tableColumn.column.openDetails(
            true,
            props.tableRow.row.type === 'House'
              ? props.tableRow.row.prefix
              : null,
            props.tableRow.row.type === 'House'
              ? props.tableRow.row.serialNumber
              : null,
            props.tableRow.row.type === 'House'
              ? null
              : props.tableRow.row.lastId,
            props.tableRow.row.status,
            props.tableRow.row.userCreated,
            props.tableRow.row.userSubmission,
            props.tableRow.row.manifestCreated,
            props.tableRow.row.lastRequestDate,
            props.tableRow.row.lastResponseDate,
            props.tableRow.row,
            props.tableColumn.column.getManifests,
            1
          );
        }}
      >
        <StatusCell {...props} />
      </Table.Cell>
    );
  }
  return <Table.Cell {...props} />;
};

const ContainerTable = ({ ...restProps }) => (
  <Table.Container style={{ overflow: 'auto' }} {...restProps} />
);

const StatusCell = (props) => (
  <Status
    key={`${props.serialNumber}-${props.flightNumber}-${props.type}`}
    {...props}
  />
);

const HAWBCell = ({ value, row, column, ...restProps }) => {
  return (
    <Table.Cell {...restProps}>
      <span
        style={{
          cursor: 'pointer',
          textDecoration: 'underline',
        }}
        onClick={async (e) => {
          await restProps.tableColumn.column.setRow({
            currentTab: 0,
            index: 0,
            row: restProps.tableRow.row,
            prefix: restProps.tableRow.row.prefix,
            serialNumber: restProps.tableRow.row.serialNumber,
            hawbNumber: restProps.tableRow.row.hawbNumber,
            isHouse: true,
            type: 'House',
            impCarrier: restProps.tableRow.row.impCarrier,
            arrivalDate: restProps.tableRow.row.arrivalDate,
            flightNumber: restProps.tableRow.row.flightNumber,
            // Cambiar por la fecha de creacion
            date:
              restProps.tableRow.row.manifestCreated ||
              restProps.tableRow.row.lastRequestDate,
          });
          await restProps.tableColumn.column.openDetails(
            true,
            restProps.tableRow.row.type === 'House'
              ? restProps.tableRow.row.prefix
              : null,
            restProps.tableRow.row.type === 'House'
              ? restProps.tableRow.row.serialNumber
              : null,
            restProps.tableRow.row.type === 'House'
              ? null
              : restProps.tableRow.row.lastId,
            restProps.tableRow.row.status,
            restProps.tableRow.row.userCreated,
            restProps.tableRow.row.userSubmission,
            restProps.tableRow.row.manifestCreated,
            restProps.tableRow.row.lastRequestDate,
            restProps.tableRow.row.lastResponseDate,
            restProps.tableRow.row,
            restProps.tableColumn.column.getManifests,
            0
          );
        }}
      >
        {value}
      </span>
    </Table.Cell>
  );
};

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
    backgroundColor: '#0d112b',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
    color: 'white',
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function Houses(props) {
  const [loading, setLoading] = useState(true);
  const [isOpen, setIsOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [rows, setRows] = useState([]);
  const [filteredRows, setFilteredRows] = useState([]);
  const [selectAllCheckbox, setSelectAllCheckbox] = useState(false);
  const [select1C, setSelect1C] = useState(false);
  const [selectAMSHold, setSelectAMSHold] = useState(false);
  const [open, setOpen] = useState(false);
  const [openDeleteDraft, setOpenDeleteDraft] = useState(false);
  const [title] = useState('');
  const [profile] = useState(JSON.parse(localStorage.profile));
  const [selectAll, setSelectAll] = useState('Add');
  const {
    awb,
    FullScreenClose,
    open2,
    openDetails,
    setRow,
    handleSetRowsStatus,
    company,
  } = props;
  const classes = useStyles();
  const [deleteManifestEnabled, setDeleteManifestEnabled] = useState(false);
  const [createFSQEnabled, setCreateFSQEnabled] = useState(false);

  const handleCancel = () => {
    FullScreenClose();
  };
  const [data] = useState({
    prefix: '',
    serialNumber: '',
    hawbNumber: '',
    close: null,
  });
  const [type] = useState('FSQ');
  const [progModal, setProgModal] = useState({
    open: false,
    text: '',
    progress: 0,
  });

  const getManifests = async (page) => {
    let number = 1;
    let newRows = [];
    for (let index = 0; index < 100; index++) {
      let result = await feathers.service('house-status').find({
        query: {
          $house: {
            prefix: props.row.prefix,
            serialNumber: props.row.serialNumber,
            skip: index * 10000,
          },
        },
      });
      if (result.length === 0) {
        break;
      }
      for (const row of result) {
        row.number = number;
        number++;
        if (!row.hasOwnProperty('1C')) {
          row['1C'] = 0;
        }
        if (!row.hasOwnProperty('AMSHold')) {
          row['AMSHold'] = 0;
        }
        row.checked = false;
        row.action = 'Add';
        newRows.push(row);
      }
    }
    setRows(newRows);
    setFilteredRows(newRows);
    setLoading(false);
    return true;
  };

  useEffect(() => {
    setLoading(true);
    setSelectAllCheckbox(false);
    if (props.awb.length > 3) {
      setCurrentPage(0);
      getManifests(0);
    }
    setCreateFSQEnabled(false);
  }, [props.awb, props.row.flightNumber, open2]);

  const handleClose = () => {
    setOpen(false);
  };

  const handleCloseDeleteDrafts = () => {
    setOpenDeleteDraft(false);
  };

  const sendManifest = async () => {
    let data = rows.filter((x) => x.checked);

    let total = data.length;
    let progress = 0;
    let percent = Math.ceil((progress / total) * 100);
    let text = `${progress} of ${total} documents sent...`;
    let open = true;
    setProgModal({ open, text, progress: percent });

    let body = [];
    for (const row of data) {
      body.push({
        prefix: row.prefix,
        serialNumber: row.serialNumber,
        hawbNumber: row.hawbNumber,
        arrivalRef: row.arrivalRef || null,
        action: row.action,
        type: 'House',
        status: row.status,
        requestDate: row.lastRequestDate,
      });
    }
    const headers = new Headers();
    headers.append('content-type', 'application/json');
    headers.set('Authorization', localStorage.token);
    console.log('Sending...');
    try {
      let res = await feathers.service('send').create({
        body: body,
        user: profile.email,
        company: company,
        type: 'House',
      });

      //Modal of docs sent
      text = `${total} of ${total} documents sent...`;
      percent = Math.ceil((total / total) * 100);
      setProgModal({ open, text, progress: percent });

      //Function to return the actions as Add
      setActionToAdd('Add');

      setTimeout(() => {
        setProgModal({ ...progModal, open: false });
      }, 2000);

      console.log(res);
    } catch (error) {
      console.log('error.message: ', error.message);

      setProgModal({ ...progModal, open: false });
      toast.error('SEND ERROR!... ' + error.message);
    }
  };

  const filters = async (C1, amsHold) => {
    console.log('filter', C1, amsHold);
    let rowsCopy = rows;
    if (C1) {
      rowsCopy = rowsCopy.filter((x) => x['1C'] !== 0);
    }
    if (amsHold) {
      rowsCopy = rowsCopy.filter((x) => x.AMSHold !== 0 || x.wasHold);
    }
    setFilteredRows(rowsCopy);
  };

  const refreshManifest = async () => {
    setLoading(true);
    setCurrentPage(0);
    getManifests(0);
  };
  const CreateFSQs = async (code) => {
    setCreateFSQEnabled(false);
    let FSQList = [];
    console.log(selection);
    for (const select of selection) {
      FSQList.push({
        SMI: { componentId: 'FSQ' },
        AWB: {
          prefix: rows[select].prefix,
          serialNumber: rows[select].serialNumber,
          hawbNumber: rows[select].hawbNumber,
        },
        FSQ: {
          componentId: 'FSQ',
          requestCode: code,
        },
      });
    }
    onSelection([]);
    // Axios.defaults.headers.common['Authorization'] = localStorage.token;
    // const response = await Axios.post(
    //   process.env['REACT_APP_CCAPI'] + `/createFSQs/${company ? company : ''}`,
    //   FSQList
    // );
    // if (response && response.data && response.data.status) {
    //   getManifests(0);
    //   toast.info("FSQ's created and sent");
    // }
  };

  const deleteDrafts = async (manifests) => {
    let hawbArray = [];
    let ids = manifests.map((value) => {
      hawbArray.push(value.hawbNumber);
      return value._id;
    });

    Axios.defaults.headers.common['Authorization'] = localStorage.token;
    const response = await Axios.post(
      process.env['REACT_APP_CCAPI'] + '/deletedraftshouses/',
      { ids }
    );

    if (response.data.success) {
      props.row.rowNo.stats.total = props.row.rowNo.stats.total - ids.length;
      props.row.rowNo.stats.draft = props.row.rowNo.stats.draft - ids.length;
      setSelectAllCheckbox(false);
      setDeleteManifestEnabled(false);
      setCreateFSQEnabled(false);
      toast.info(response.data.message);
      setCurrentPage(0);
      getManifests(0);
      handleSetRowsStatus(props.row.rowNo);
    }
  };

  const createFSNs = () => setIsOpen(true);
  const [selection, setSelection] = useState([]);

  let onSelection = (selected) => {
    let newRow = JSON.parse(JSON.stringify(filteredRows));

    newRow = newRow.map((row) => {
      delete row.checked;
      return row;
    });

    selected.map((index) => {
      newRow[index].checked = true;
      newRow[index].action = selectAll;
    });

    let rowDraft = newRow.filter(
      (x) => x.status === 'DRAFT' && x.checked === true
    ).length;
    let rowChecked = newRow.filter((x) => x.checked).length;
    setCreateFSQEnabled(rowDraft > 0 ? false : true && rowChecked > 0);
    if (rowDraft === rowChecked && rowDraft > 0) {
      setDeleteManifestEnabled(true);
    } else {
      setDeleteManifestEnabled(false);
    }

    setFilteredRows(newRow);

    setSelection(selected);
  };

  const handleChange = (action) => {
    let data = JSON.parse(JSON.stringify(filteredRows));
    data = data.map((it) => {
      if (it.checked) return { ...it, action };
      return it;
    });
    setFilteredRows(data);
    setSelectAll(action);
  };

  const setActionToAdd = (action) => {
    let data = JSON.parse(JSON.stringify(filteredRows));
    data = data.map((it) => {
      return { ...it, action };
    });
    setFilteredRows(data);
    setSelectAll(action);
  };

  if (loading) {
    return (
      <Dialog
        maxWidth="xl"
        fullWidth={true}
        open={open2}
        onClose={handleCancel}
        onBackdropClick={handleCancel}
        onEscapeKeyDown={handleCancel}
        TransitionComponent={Transition}
      >
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleCancel}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              {awb}
            </Typography>

            <Button
              variant="contained"
              color="primary"
              style={{ color: 'white' }}
              onClick={async (e) => {}}
            >
              Refresh
            </Button>
            <MenuButton
              disabled={!createFSQEnabled}
              id="menu-button-1"
              raised
              simplifiedMenu={false}
              style={{
                backgroundColor: !createFSQEnabled ? '#8f9096' : '#3f51b5',
                color: '#fff',
                marginRight: '16px',
              }}
              anchor={{ x: 'center', y: 'bottom' }}
              menuItems={[]}
            >
              Create FSQ
            </MenuButton>
            <SelectField
              id="select-field"
              style={{}}
              className={'xsxsxs'}
              inputStyle={{ color: 'white' }}
              dropdownIcon={
                <FontIcon style={{ color: 'white' }}>arrow_drop_down</FontIcon>
              }
              disabled={rows.filter((x) => x.checked).length === 0}
              value={selectAll}
              menuItems={['Add', 'Change', 'Cancel']}
              onChange={handleChange}
            />
            <Button
              disabled={rows.filter((x) => x.checked).length === 0}
              style={{
                marginLeft: '15px',
                backgroundColor:
                  rows.filter((x) => x.checked).length === 0
                    ? '#8f9096'
                    : '#3f51b5',
              }}
              variant="contained"
              color="primary"
              onClick={(e) => {
                sendManifest();
              }}
            >
              Send Manifest
            </Button>
            <Button
              disabled={!deleteManifestEnabled}
              style={{
                marginLeft: '15px',
                backgroundColor: !deleteManifestEnabled ? '#8f9096' : '#3f51b5',
              }}
              variant="contained"
              color="primary"
              onClick={(e) => {
                setOpenDeleteDraft(true);
              }}
            >
              Delete Manifest
            </Button>
          </Toolbar>
        </AppBar>
        <div style={{ marginTop: '40px', marginBottom: '40px' }}>
          <CircularProgress
            id="loading"
            centered={true}
            className="loading-blue"
            scale={2}
          ></CircularProgress>
        </div>
      </Dialog>
    );
  }

  return (
    <Fragment>
      <Dialog
        maxWidth="xl"
        width="xl"
        open={open2}
        onClose={handleCancel}
        onBackdropClick={handleCancel}
        onEscapeKeyDown={handleCancel}
        TransitionComponent={Transition}
      >
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleCancel}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>

            <div style={{ display: 'flex', flexGrow: 1, alignItems: 'center' }}>
              <Typography
                variant="h6"
                className={classes.title}
                style={{ flexGrow: 0, flexBasis: 'auto' }}
              >
                {awb}
              </Typography>
              <FormControlLabel
                control={
                  <Checkbox
                    style={{ color: 'white' }}
                    checked={select1C}
                    onClick={async (e) => {
                      setSelect1C(!select1C);
                      setSelection([]);
                      setCreateFSQEnabled(false);
                    }}
                    onChange={async (e) => {
                      await filters(e.target.checked, selectAMSHold);
                    }}
                    inputProps={{
                      'aria-label': 'primary checkbox',
                    }}
                  />
                }
                label="1C"
                style={{ marginLeft: '16px' }}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    style={{ color: 'white' }}
                    checked={selectAMSHold}
                    onClick={async (e) => {
                      setSelectAMSHold(!selectAMSHold);
                      setSelection([]);
                      setCreateFSQEnabled(false);
                    }}
                    onChange={async (e) => {
                      await filters(select1C, e.target.checked);
                    }}
                    inputProps={{
                      'aria-label': 'primary checkbox amsHold',
                    }}
                  />
                }
                label="AMS Hold"
                style={{ marginLeft: '16px' }}
              />
            </div>

            <Button
              variant="contained"
              color="primary"
              style={{ color: 'white', marginRight: '10px' }}
              onClick={async (e) => {
                refreshManifest();
              }}
            >
              Refresh
            </Button>
            <MenuButton
              disabled={profile.role === 'shipper' || !createFSQEnabled}
              id="menu-button-1"
              raised
              simplifiedMenu={false}
              style={{
                backgroundColor:
                  profile.role === 'shipper' || !createFSQEnabled
                    ? '#8f9096'
                    : '#019EE1',
                color: '#fff',
                marginRight: '16px',
                height: '100%',
              }}
              anchor={{ x: 'center', y: 'bottom' }}
              menuItems={[
                <ListItem
                  key={'01'}
                  primaryText="Create FSQ 01"
                  secondaryText="Routing Information"
                  onClick={async (e) => {
                    await CreateFSQs('01');
                  }}
                />,
                <ListItem
                  key={'02'}
                  primaryText="Create FSQ 02"
                  secondaryText="Current Status"
                  onClick={async (e) => {
                    await CreateFSQs('02');
                  }}
                />,
                <ListItem
                  key={'03'}
                  primaryText="Create FSQ 03"
                  secondaryText="Nominated Agent"
                  onClick={async (e) => {
                    await CreateFSQs('03');
                  }}
                />,
                <ListItem
                  key={'04'}
                  primaryText="Create FSQ 04"
                  secondaryText="Retransmission of FSN"
                  onClick={async (e) => {
                    await CreateFSQs('04');
                  }}
                />,
                <ListItem
                  key={'05'}
                  primaryText="Create FSQ 05"
                  secondaryText="Retransmission of FSC/10"
                  onClick={async (e) => {
                    await CreateFSQs('05');
                  }}
                />,
              ]}
            >
              Create FSQ
            </MenuButton>
            <Button
              disabled={
                profile.role === 'shipper' ||
                filteredRows.filter((x) => x.checked).length === 0 ||
                filteredRows.filter((x) => x.status === 'SENT' && x.checked)
                  .length
              }
              style={{
                marginRight: '15px',
                backgroundColor:
                  profile.role === 'shipper' ||
                  filteredRows.filter((x) => x.checked).length === 0 ||
                  filteredRows.filter((x) => x.status === 'SENT' && x.checked)
                    .length
                    ? '#8f9096'
                    : '#019EE1',
              }}
              variant="contained"
              color="primary"
              onClick={createFSNs}
            >
              Create FSN
            </Button>
            <SelectField
              id="select-field"
              style={{}}
              className={'xsxsxs'}
              inputStyle={{ color: 'white' }}
              dropdownIcon={
                <FontIcon style={{ color: 'white' }}>arrow_drop_down</FontIcon>
              }
              disabled={
                rows.filter((x) => x.checked).length === 0 ||
                rows.filter((x) => x.status === 'SENT' && x.checked).length
              }
              value={selectAll}
              menuItems={['Add', 'Change', 'Cancel']}
              onChange={handleChange}
            />
            <Button
              disabled={
                profile.role === 'shipper' ||
                rows.filter((x) => x.checked).length === 0 ||
                rows.filter((x) => x.status === 'SENT' && x.checked).length
              }
              style={{
                marginLeft: '15px',
                backgroundColor:
                  profile.role === 'shipper' ||
                  rows.filter((x) => x.checked).length === 0 ||
                  rows.filter((x) => x.status === 'SENT' && x.checked).length
                    ? '#8f9096'
                    : '#019EE1',
              }}
              variant="contained"
              color="primary"
              onClick={() => {
                sendManifest();
                onSelection([]);
              }}
            >
              Send Manifest
            </Button>

            <Button
              disabled={!deleteManifestEnabled}
              style={{
                marginLeft: '15px',
                backgroundColor: !deleteManifestEnabled ? '#8f9096' : '#019EE1',
              }}
              variant="contained"
              color="primary"
              onClick={(e) => {
                setOpenDeleteDraft(true);
              }}
            >
              Delete Manifest
            </Button>
          </Toolbar>
        </AppBar>
        <div>
          <DialogForm title={title} open={open} onClose={handleClose}>
            {type === 'FSQ' ? (
              <FSQ {...data}></FSQ>
            ) : type === 'FSN' ? (
              <FSN {...data}></FSN>
            ) : (
              <FDM {...data}></FDM>
            )}
          </DialogForm>
          <Grid
            rows={filteredRows}
            style={{ backgroundColor: 'red' }}
            columns={[
              {
                name: 'number',
                title: '#',
                getCellValue: (row) => `${row.number || ''}`,
              },
              {
                name: 'hawb',
                title: 'HAWB',
                getCellValue: (row) => {
                  return `${row.hawbNumber || ''}${
                    (row.arrivalRef && ' - ') || ''
                  }${row.arrivalRef || ''}`;
                },
                setRows: setRows,
                openDetails: openDetails,
                setRow: setRow,
                rows: rows,
                getManifests: getManifests,
                setDeleteManifestEnabled: setDeleteManifestEnabled,
                setCreateFSQEnabled: setCreateFSQEnabled,
              },
              {
                name: 'status',
                title: 'Status',
                getCellValue: (row) => {
                  return `
                ${row.status} (${row.docType}) 
                ${row.code || ''} 
                ${
                  row.message ? row.message : FSCMessage(row.code).message || ''
                }
            `;
                },
                setRows: setRows,
                openDetails: openDetails,
                setRow: setRow,
                rows: rows,
                getManifests: getManifests,
              },
              {
                name: 'marksAndNumbers',
                title: 'Marks and Numbers',
                getCellValue: (row) => row.marksAndNumbers || '',
              },
              {
                name: '1C',
                title: '1C',
                getCellValue: (row) => (row['1C'] ? row['1C'] : 0),
              },
              {
                name: 'AMSHold',
                title: 'AMS Hold',
                getCellValue: (row) => (row['AMSHold'] ? row['AMSHold'] : 0),
              },
              {
                name: 'request_date',
                title: 'Submission Date',
                getCellValue: (row) =>
                  row.lastRequestDate !== 0
                    ? moment(row.lastRequestDate).format('MM/DD/YYYY HH:mm:ss')
                    : '',
              },
              {
                name: 'response_date',
                title: 'Response Date',
                getCellValue: (row) =>
                  row.lastResponseDate !== 0
                    ? moment(row.lastResponseDate).format('MM/DD/YYYY HH:mm:ss')
                    : '',
              },
              {
                name: 'actions',
                title: 'Actions',
                getCellValue: (row) => {
                  return (
                    <SelectField
                      id="select-field"
                      value={row.action}
                      disabled={
                        profile.role === 'shipper' || row.status === 'SENT'
                      }
                      menuItems={['Add', 'Change', 'Cancel']}
                      onChange={(action) => {
                        const pos = row.number - 1;
                        rows[pos].action = action;
                      }}
                    />
                  );
                },
              },
              {
                name: 'options',
                title: 'Options',
                getCellValue: (row) => {
                  return (
                    <Button
                      variant="contained"
                      disabled={
                        profile.role === 'shipper' && row.status !== 'DRAFT'
                      }
                      color="primary"
                      style={{ color: 'white' }}
                      onClick={async (e) => {
                        props.history.push(
                          `create-manifest?id=${row.lastId}&clone=true&status=${row.status}`
                        );
                      }}
                    >
                      Clone
                    </Button>
                  );
                },
              },
            ]}
          >
            <RowDetailState />
            <SortingState
              defaultSorting={[{ columnName: 'product', direction: 'asc' }]}
            />
            <IntegratedSorting />
            <FilteringState
              defaultFilters={[]}
              onFiltersChange={(e) => {
                onSelection([]);
              }}
            />
            <IntegratedFiltering />
            <SelectionState
              selection={selection}
              onSelectionChange={onSelection}
            />
            <IntegratedSelection />
            <PagingState
              currentPage={currentPage}
              pageSize={100}
              onCurrentPageChange={(e) => {
                setLoading(true);
                setCurrentPage(e);
                setTimeout(() => {
                  setLoading(false);
                }, 100);
              }}
            />
            <IntegratedPaging />
            <VirtualTable
              columnExtensions={[
                { columnName: 'status', wordWrapEnabled: true },
              ]}
              containerComponent={ContainerTable}
              cellComponent={Cell}
            />
            <TableSelection showSelectAll />
            <TableColumnResizing
              defaultColumnWidths={[
                { columnName: 'hawb', width: 180 },
                { columnName: 'status', width: 150 },
                { columnName: 'number', width: 55 },
                { columnName: 'marksAndNumbers', width: 150 },
                { columnName: '1C', width: 100 },
                { columnName: 'AMSHold', width: 100 },
                { columnName: 'request_date', width: 150 },
                { columnName: 'response_date', width: 150 },
                { columnName: 'actions', width: 150 },
                { columnName: 'options', width: 150 },
              ]}
            />
            <TableFilterRow />
            <TableHeaderRow showSortingControls />
            <PagingPanel containerComponent={PagingContainer} />
          </Grid>
        </div>
      </Dialog>
      <DialogForm open={openDeleteDraft} onClose={handleCloseDeleteDrafts}>
        <DeleteManifestDraftsView
          close={handleCloseDeleteDrafts}
          deletes={deleteDrafts}
          manifests={rows.filter((x) => x.checked)}
        ></DeleteManifestDraftsView>
      </DialogForm>
      <CreateFSN
        setIsOpen={setIsOpen}
        isOpen={isOpen}
        filteredRows={rows}
        selection={selection}
        company={company || null}
      />
      <SentModal id="sentModalHouse" {...progModal} />
    </Fragment>
  );
}
